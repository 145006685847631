import './index.scss';

import Icon, { ICON_NAME } from '@slkit/Icon';
import Maybe from 'graphql/tsutils/Maybe';
import React from 'react';

import { CurrencyEnum } from '../../../__types__/globalTypes';
import { TNormalizedTravelPass } from '../../../components/TravelPasses/TravelPassesContainer';
import { formatPrice } from '../../helpers';
import Radio from '../Radio';
import SegmentedControl from '../SegmentedControl';

interface IProps {
    data: {
        [key: string]: TNormalizedTravelPass[];
    };
    selected: TNormalizedTravelPass;
    onSelect: (travelPass: TNormalizedTravelPass) => void;
}

const TravelPassTypeOptionButtonTitle = ({
    price,
    selected,
    title,
}: {
    price: Maybe<number>;
    selected: boolean;
    title: Maybe<string>;
}) => (
    <div className="TravelPassType__VariantOption">
        {selected && <Icon name={ICON_NAME.CHECK} />}
        <span
            className="TravelPassType__VariantOptionTitle"
            style={{
                paddingRight: '0.5rem',
            }}
        >
            {title}
        </span>
        <span className="TravelPassType__VariantOptionPrice">
            {price !== null && price !== undefined
                ? formatPrice(price, CurrencyEnum.EUR)
                : '-'}
        </span>
    </div>
);

const TravelPassTypeOption = ({
    data,
    onSelect,
    selected,
}: {
    data: TNormalizedTravelPass[];
    onSelect: IProps['onSelect'];
    selected: TNormalizedTravelPass;
}) => {
    const name = data[0].name;
    const description = data[0].description;

    const options = data.map(pass => ({
        id: pass.zonePairPriceId!,
        title: (
            <TravelPassTypeOptionButtonTitle
                price={pass.price}
                selected={selected.zonePairPriceId === pass.zonePairPriceId}
                title={pass.passengerType}
            />
        ),
    }));

    return (
        <div className="TravelPassType">
            <Radio
                label={
                    <div>
                        <div className="TravelPassType__Label">{name}</div>
                        <div className="TravelPassType__Description">
                            {description}
                        </div>
                        <div className="TravelPassType__VariantSelect">
                            <SegmentedControl
                                activeOptionId={selected.zonePairPriceId!}
                                options={options}
                                onChange={(id, n) => onSelect(data[n])}
                            />
                        </div>
                    </div>
                }
                onChange={() => onSelect(data[0])}
                checked={data
                    .map(i => i.zonePairPriceId)
                    .includes(selected.zonePairPriceId)}
            />
        </div>
    );
};

const TravelPassTypeSelector: React.FC<IProps> = ({
    data,
    selected,
    onSelect,
}: IProps) => {
    const types = Object.keys(data);

    return (
        <>
            {types.map(key => (
                <TravelPassTypeOption
                    key={key}
                    data={data[key]}
                    onSelect={onSelect}
                    selected={selected}
                />
            ))}
        </>
    );
};

export default TravelPassTypeSelector;
